jQuery( document ).ready(function() {

  function scrollToAnchor(href) {

    if (jQuery('body').hasClass('home')) {
      document.querySelector(href.replace('/', '')).scrollIntoView({ behavior: 'smooth' }); 
    } else {
      if (jQuery('html').attr('lang') == 'en') {
        window.location.href ='/en' + href;
      } else {
        window.location.href = '/' + href;
      }
      
    }
  }

  jQuery('.js-button--scroll-down').click(function(){
    var href = jQuery(this).data('anchor');
    scrollToAnchor('#' + href);
  });

   jQuery('.mega-menu a').click(function(e){
      e.preventDefault();
       var href = jQuery(this).attr('href');
      if(document.location.pathname == href.split("#")[0]){
        jQuery.when(jQuery('.js-toggle-mega-menu').removeClass('slide-up slide-down')).done(function() {
          scrollToAnchor(href);
       });
      }
      else{
        window.location.href =  window.location.protocol+"//"+window.location.hostname+href;
      } 
   });


jQuery('.js-menu-contact a, .js-product-menu-item a').click(function(e){
      e.preventDefault();
       var href = jQuery(this).attr('href');
       if (jQuery('.js-toggle-mega-menu').css('display') == 'block') {
          jQuery.when(jQuery('.js-toggle-mega-menu').removeClass('slide-up slide-down')).done(function() {
             scrollToAnchor(href);
         });
       } else {
          scrollToAnchor(href);
       }
      if (isMobile == true) {
          jQuery('.js-toggle-menu').toggleClass('is-open');
          jQuery('nav').slideToggle();
          jQuery('header').siblings('nav').toggleClass('is-open');
       }
   });

   jQuery('.js-logo').click(function(event) {
       scrollToAnchor('#top');
    });
  
}); 
