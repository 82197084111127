jQuery( document ).ready(function() {
  jQuery(window).scroll(function(){
      if (isMobile == false) {
          if (jQuery(window).scrollTop() >= 100) {
            jQuery('.slogan').addClass('slide-left');
            jQuery('.site-header').addClass("sticky");
            jQuery('.site-header .logo img,.site-header .logo .background-red').addClass("sticky");
            if (jQuery('.js-toggle-mega-menu').hasClass('slide-down'))
              jQuery('.js-toggle-mega-menu').removeClass('slide-down').addClass('slide-up');
        }else{
          jQuery('.slogan').removeClass('slide-left');
          jQuery('.site-header').removeClass("sticky");
          jQuery('.site-header .logo img,.site-header .logo .background-red').removeClass("sticky");
          if (jQuery('.js-toggle-mega-menu').hasClass('slide-up'))
            jQuery('.js-toggle-mega-menu').removeClass('slide-down').addClass('slide-up');
        }
      }  
  });
});